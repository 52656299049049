<template>
  <div class="projects">
    <div class="bg"></div>

    <div class="wrap">
      <div class="top col-b_ animated fadeInUp container">
        <section class="zero">
          <p class="content">
            {{ $t("projects.title") }}
          </p>
        </section>
        <div class="nb" style="width: 65%"></div>
        <div class="mt-9 pt-9 title-1">{{ $t("projects.PROJECT") }}</div>
      </div>

      <div class="content animated fadeInUp">
        <v-container
          class="item1"
          v-for="(item, index) of content"
          :key="index"
        >
        <a :href="item.link">
          <v-row class="white black--text">
            <v-col cols="12" md="10">
              <div class="title">{{ $t(item.title) }}</div>
              <div class="info1 row-b">
                <div class="detail">
                  <div class="text">{{ $t(item.text) }}</div>
                </div>
              </div>
               
               
            </v-col>
            <v-col cols="12" md="2" class="left py-0 px-0">
              <v-responsive :aspect-ratio="736 / 974" class="">
                <img :src="item.ims" alt="" style="width: 100%; height: 100%" />
              </v-responsive>
            </v-col>
          </v-row>
          </a>
        </v-container>
      </div>
    </div>
    <ButtomNav></ButtomNav>
  </div>
</template>
<script>
import ButtomNav from "@/components/buttomNav.vue";
export default {
  components: {
    ButtomNav,
  },
  data() {
    return {
      content: [
        {
          title: "projects.titles.one",
          text: "projects.text.one",
          link: "https://polkadot.network",
          about: "Polkadot",
          ims: require("../assets/img/Polkadot.png"),
        },
        {
          title: "projects.titles.tow",
          text: "projects.text.tow",
          link: "https://kusama.network",
          about: "Kusama",
          ims: require("../assets/img/Kusama.png"),
        },
        
        {
          title: "projects.titles.four",
          text: "projects.text.four",
          link: "https://phala.network",
          about: "Phala",
          ims: require("../assets/img/Phala.png"),
        },
        {
          title: "projects.titles.five",
          text: "projects.text.five",
          link: "https://transx.io",
          about: "TransX",
          ims: require("../assets/img/TransX.png"),
        },
        {
          title: "projects.titles.six",
          text: "projects.text.six",
          link: "https://listen.io",
          about: "Listen",
          ims: require("../assets/img/Listen.png"),
        },
        {
          title: "projects.titles.seven",
          text: "projects.text.seven",
          link: "https://ibo.io",
          about: "IBO",
          ims: require("../assets/img/IBO.png"),
        },
        {
          title: "projects.titles.eight",
          text: "projects.text.eight",
          link: "https://dico.io",
          about: "DICO",
          ims: require("../assets/img/DICO.png"),
        },
        {
          title: "projects.titles.tp",
          text: "projects.text.tp",
          link: "https://www.tokenpocket.pro/",
          about: "TokenPocket",
          ims: require("../assets/img/tp.png"),
        },
        {
          title: "projects.titles.ogx",
          text: "projects.text.ogx",
          link: "https://www.ogx.network/#/",
          about: "OGX",
          ims: require("../assets/img/ogx.png"),
        },
        {
          title: "projects.titles.ins3",
          text: "projects.text.ins3",
          link: "https://www.ins3.finance/",
          about: "INS3",
          ims: require("../assets/img/ins3.png"),
        },
        // {
        //   title: "projects.titles.nine",
        //   text: "projects.text.nine",
        //   link: "https://polkaworld.org",
        //   about: "PolkaWorld",
        //   ims: require("../assets/img/polkaWorld.png"),
        // },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.projects {
  color: #fff;
  > .bg {
    width: 100%;
    // height: 10px;
    // margin: 40vh 0;
    // margin-bottom: 370vh;
  }
  > .wrap {
    width: 100%;
    // min-height: 20%;
    // position: absolute;
    // left: 0;
    // top: 0;
    background: #000;
    > .top {
      width: 100%;
      padding: 10vw 12.5vw 5vw;
      background: #000;
      box-sizing: border-box;
      .zero {
        padding-right: 6vw;
        padding-bottom: 10vh;
        .content {
          color: #fff;
          font-size: 30px;
          line-height: 1.5;
          font-weight: 600;
        }
      }
      > .left {
        width: 30%;
        font-size: 35px;
        font-weight: bold;
        color: #fff;
        margin: 15vw 0 0;
      }
    }
    > .content {
      // width: 68vw;
      margin: 0 auto;
      margin-top: 15vh;
      // margin-bottom: 30vh;
      // background: #f00
      > .item:hover .go {
        width: 100% !important;
      }
      > .item:hover .i1 {
        color: #fff;
      }
      .item1 {
        width: 100%;
        // box-shadow: 0 0 2vw #999;
        background: #000;
        margin-bottom: 15vh;
        cursor: pointer;
        .left {
          // width: 38%;
          // height: 70vh;
          // border: 1px solid #fff;
          box-sizing: border-box;
        }
      }
    }
  }
}
@media screen and (max-width: 940px) {
  // 移动设备

  .projects {
    > .wrap {
      padding: 0 10px;
      > .top {
        height: auto;
        padding: 5vh 0;
        padding-top: 15vh;
        > div {
          width: 100%;
        }
        .zero {
          padding: 0 0 10vh;
          .content {
            font-size: 30px;
            font-weight: 500;
          }
        }
        .title {
          font-size: 8vw;
          padding-bottom: 3vh;
        }
        .some {
          font-size: 7.5vw;
        }
      }
      > .content {
        width: 100%;
        // margin: -3vh auto 0 ;
        .item1 {
          // height: auto;
          // flex-direction: column;
          margin-bottom: 15vw;
          > div {
            // width: 100%;
            min-height: 20vh;
          }
          .left {
            display: none;
          }
        }
      }
    }
  }
}
</style>